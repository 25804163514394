html {
	scroll-behavior: smooth;
}
body {
	@include fadeIn($duration: 1s,$count: 1,$delay: 0,$function: ease, $fill: both);
	scroll-behavior: smooth;
	overflow-x: hidden;
}


// Wrapper principal
.main-wrapper {
	position: absolute;
	width:100%;
	margin-top: 61px;
	@include media-breakpoint-down(md) {
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
	}
	@extend .d-flex, .flex-column;
	padding: 2.5rem 2.5rem;
	min-height: 90vh;
	opacity: 1;
	display: block;
	z-index: 500;
	@extend .transition;
	@include media-breakpoint-up(lg) {
		margin-top: 77px;
	}
	@include media-breakpoint-up(xl) {
		margin-top: 111px;
	}
	@include media-breakpoint-up(xxl) {
		margin-top: 134px;
	}
	&.hidding {
	}
	&.showing {
		display: block;
	}
	&.inactive {
		position: fixed;
		top:0;
		left: 0;


	}
}

// Wrapper caché
.hidden-wrapper {
	position: absolute;
	display: none;
	margin-top: 61px;
	@extend .flex-column;
	@include media-breakpoint-down(md) {
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
	}
	padding: 2.5rem 2.5rem;
	min-height: 100vh;
	width: 100%;
	top:0;
	z-index: 600;
	@include media-breakpoint-up(lg) {
		margin-top: 77px;
	}
	@include media-breakpoint-up(xl) {
		margin-top: 111px;
	}
	@include media-breakpoint-up(xxl) {
		margin-top: 134px;
	}
	&.showing {
		@include slideInRight($duration: 0.2s,$count: 1,$delay: 0,$function: ease, $fill: both);
		display: flex;
	}
	&.show {
		@include slideInRight($duration: 0.2s,$count: 1,$delay: 0,$function: ease, $fill: both);
		display: flex;
	}

}

// Footer interieur
.footer-interior {
	@extend .mt-auto;
	padding-top: 20px;
	.link {
		text-align: center;
		a {
			color: #FFFFFFCC;
			text-transform: uppercase;
			@include font-size(14);
			font-family: $nuBL;
			letter-spacing: 1.4px;
		}
	}
}

// Footer Home
.footer {
	margin-top:3rem;
	.gotop {
		border-radius: 50%;
		border: solid 1px $green;
		display: inline-block;
		padding: 8px;
		@extend .transition;
		&:hover {
			text-decoration: none;
			border: solid 1px $orange;
			i {
				&:before {
					color:$orange;
				}
			}
		}
		i {
			&:before {
				transform: rotate(180deg);
				display: block;
				@extend .transition;
			}

		}
	}
	p {
		@include font-size(14);
		font-family: $nuBL;
		text-transform: uppercase;
		margin-top: 20px;
	}
}

// Boutons
.btn-proxi {
	@include font-size(18);
	font-family: $nuEB;
	border-radius: 30px;
	padding: 12px 15px;
	margin-top: 10px;
	box-shadow: 0 3px 20px #00364540;
	border:solid 1px inherit;
	min-width: 200px;
	&-orange {
		background-color: $orange;
		color: $w;
		border:solid 1px $orange;
		&:hover {
			background-color: $w;
			color: $orange;
		}
	}
	&-green {
		background-color: $green;
		color: $w;
		border:solid 1px $green;
		&:hover {
			background-color: $w;
			color: $green;
		}
	}
	&-white {
		background-color: $w;
		color: $orange;
		border:solid 1px $w;
		&:hover {
			background-color: $orange;
			color: $w;
			border:solid 1px $orange;
		}
	}
}

.btn-payment {
	display: none;
	margin-top: 30px;
	&.active {
		display: block;
		@include fadeInUp($duration: 0.3s,$count: 1,$delay: 0,$function: ease, $fill: both);
	}
	&:hover {
		background-color: $blue-light;
		color:$w;
		border:solid 1px $blue-light;
	}
	padding: 15px;
	min-width: 210px;
	text-align: center;
	background-color: $w;
	@include font-size(18);
	font-family: $nuEB;
	text-transform: uppercase;
	border-radius: 50px;
	color: $orange;
	border:solid 1px $w;
}

.btn-lavl, .btn-sechl {
	width: 49%;
	padding: 20px 8px 20px 34px;
	border: solid 1px rgba(#fff, 0.5);
	border-radius: 5px;
	text-transform: uppercase;
	@include font-size(16);
	font-family: $nuEB;
	color: #fff; 
	max-width: 200px;
	box-shadow: 0 5px 15px transparent;
	img.img-light {
		opacity: 0;
	}
	img.img-dark {
		opacity: 1;
	}
	&:hover, &.active {
		background-color: $w;
		color: $green;
		box-shadow: 0 5px 15px #0000006e;
		img.img-light {
			opacity: 1;
		}
		img.img-dark {
			opacity: 0;
		}
	}
}

.btn-sechl {
	margin-left: 5px;
	position: relative;
	img {
		position: absolute;
		left: 20px;
		top: 21px;
		@extend .transition;

		@include media-breakpoint-down(xs) {
			left: 12px;
			top: 19px;
		}
		@include media-breakpoint-down(sm) {
			left: 12px;
			top: 15px;
		}
	}
}

.btn-lavl {
	margin-right: 5px;
	position: relative;
	img {
		position: absolute;
		left: 20px;
		top: 13px;
		@extend .transition;
		@include media-breakpoint-down(xs) {
			left: 12px;
			top: 10px;
		}
		@include media-breakpoint-down(sm) {
			left: 12px;
			top: 7px;
		}
	}
}

.btn-pay {
	@extend .col-12, .col-sm-6, .col-md-4;
	&:focus {
		box-shadow: none;
	}
	.content {
		background-color: $w;
		text-align: center;
		border-radius: 10px;
		box-shadow: 0 5px 15px #24282926;
		color:$green;
		margin-bottom: 17px;
		padding: 32px;
		@extend .transition;
	}

	&:hover, &.active {
		.content {
			color: $w !important;
			background-color: $blue-light;
		}
		.label, .title, .avail {
			color: $w;
		}
	}
	.title {
		@include font-size(18);
		font-family: $nuEB;
		text-transform: uppercase;
		letter-spacing: 0.18px;
		@extend .transition;
		color:$green;
	}
	.label {
		text-transform: uppercase;
		color: #24282980;
		@include font-size(14);
		font-family: $nuM;
		@extend .transition;
	}
	.avail {
		text-transform: uppercase;
		@include font-size(25);
		font-family: $nuEB;
		@extend .transition;
		color:$green;
	}
}

.back-blue-light {
	.btn-pay {
		&:hover, &.active {
			.content {
				background-color: $orange;
			}

		}
	}
}

.btn-informations {
	width: 49%;
	border-radius: 0px !important;
	margin-top: 30px;
	text-transform: uppercase;
	border-bottom: 3px solid rgba(255, 255, 255, .35);
	@include font-size(18);
	font-family: $nuEB;
	color: #fff; 
	&:hover, &.active {
		color: #fff;
		border-bottom: 3px solid $orange;
	}
}
.btn-cont {
	@extend .d-flex,.justify-content-center;
}
.btn-pricing {
	background-color: #fff;
	border-radius: 50px;
	padding: 10px 30px;
	margin-top: 50px;
	max-width: 350px;
	@extend .justify-content-center;
	display: none;
	&.active {
		display: flex;
		@include fadeInUp($duration: 1s,$count: 1,$delay: 0,$function: ease, $fill: both);
	}
	.price {
		@include font-size(40);
		font-family: $nuEB;
		color: $orange;
		border-right: 1px solid #00000038;
		padding-right: 10px;
		@extend .my-auto;
	}
	.label {
		padding-left: 10px;
		@include font-size(18);
		letter-spacing: 0.18px;
		color: $orange;
		font-family: $nuEB;
		text-transform: uppercase;
		@extend .my-auto;
	}
}

.back-blue-light {
	.btn-pricing {
		border-color: $blue-light;
		.price, .label {
			color: $blue-light;
		}
		&:hover {
			background-color: $blue-light;
			border-color: #fff;
			.price {
				border-right: 1px solid #fff;
			}
			.price, .label {
				color:$w;
			}
		}
	}
	.btn-payment {
		color: $blue-light;
		&:hover {
			background-color: $orange;
			color:$w;
			border: solid 1px $orange;
		}
	}
}


.action-row {
	@include media-breakpoint-up(md) {
		justify-content: center !important;
	}
}


.backimg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	overflow: hidden;
	img {
		@extend .img-fluid;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}

.back-orange, .backorange  {
	border-color: $orange;
	.btn-pricing {
		.price, .label {
			color: $orange;
		}
		.price {
			border-right: 1px solid #fff;
		}
		&:hover {
			background-color: $orange;
			border-color: #fff;
			.price, .label {
				color:$w;
			}
		}
	}
}


.title-h1-top {
	@include font-size(25);
	font-family: $nuEB;
	text-align: center;
	text-transform: uppercase;
}
.subtitle-top {
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2.1px;
	@include line-height(10);
	margin-bottom: 15px;
	@include font-size(14);
}
.subttl {
	margin-bottom: 20px;
	font-family: $nuR;
	@include font-size(20);
	text-align: center;
	&.pulse {
		@include pulse($duration: 2s,$count: 1,$delay: 0,$function: ease, $fill: both);
	}
}



.informations-title {
	text-transform: uppercase;
	font-family: $nuEB;
	@include font-size(22);
	margin-bottom: 30px;
}


a.back {
	position: absolute;
	top: 26px;
	z-index: 1;
	left: 0;
	height: 40px;
	width: 40px;
	display: block;
	@extend .transition;
	&:hover {
		left: -10px;
	}
}

// Gmaps
.gm-style-iw-d {
	color: $green;
	font-family: $nuEB;
	@include font-size(22);
}


// Modal
.modal {
	@include fadeInUp($duration: 1s,$count: 1,$delay: 0,$function: ease, $fill: both);
	.modal-content {
		border-radius: 15px;
		box-shadow: 0 3px 50px #077C9A80;
		text-align: center;
		padding: 30px 50px;
		position: relative;
		border:none;
		.logo-big {
			max-width: 263px;
			@extend .mx-auto;
			margin-bottom: 20px;
			img {
				@extend .img-fluid;
			}
		}
		.title {
			font-family: $nuEB;
			@include font-size(25);
			color: $orange;
			margin-bottom: 20px;
		}
		.subtitle {

			font-family: $nuEB;
			@include font-size(18);
			color: #242829CC;
			margin-bottom: 20px;
		}
		.modal-header {
			border-bottom: none;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
			.close {
				width: 45px;
				height: 45px;
				background: $orange;
				border: solid 2px white;
				border-radius: 50%;
				position: absolute;
				right: -20px;
				top: -16px;
				opacity: 1 !important;
				display: flex;
				padding: 0;
				margin: 0;
				@extend .transition;
				&:hover {
					transform: rotate(180deg);
				}
				&:focus {
					outline: none;
				}
				span {
					position: relative;
					font-size: 30px;
					color: #fff;
					margin: auto;
					display: block;
					line-height: 32px;
					top: -3px;
				}
			} 
		}
		.description {
			font-family: $nuR;
			@include font-size(18);
			color: #242829CC;
		}
		a {
			color: $orange;
			font-family: $nuEB;
			@include font-size(17);
			text-decoration: underline;
			margin-top: 10px;
		}
	}
}
.modal-backdrop {
	background-color: rgba(#077C9A,0.49);
}

@media (max-width: 992px) { .backimg img { transform: translate3d(0,0,0) !important; } }


// .scrolled {
// 	.main-wrapper, .hidden-wrapper {
// 		@include media-breakpoint-up(lg) {
// 			margin-top: 57px;
// 		}
// 		@include media-breakpoint-up(xl) {
// 			margin-top: 66px;
// 		}
// 		@include media-breakpoint-up(xxl) {
// 			margin-top: 82px;
// 		}
// 	}
// } 