#informations {
	.block-lav {
		.informations-list {
			
			// SOUS NAV INFORMATIONS
			.informations-select {
				@extend .d-flex, .justify-content-between;
				overflow-x: scroll;
				height: 75px;
				overflow-y: hidden;

				.contact {
					position: relative;

					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_location.svg");
						background-repeat: no-repeat;
						width: 22px;
						height: 27px;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: -30px;
					}

					&.active {
						&:before {
							content:"";
							position: absolute;
							background: url("../img/icon_location_orange.svg");
							background-repeat: no-repeat;
							width: 22px;
							height: 27px;
							left: 0;
							right: 0;
							margin: 0 auto;
							top: -30px;
						}
					}
				}

				.horaires {
					position: relative;

					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_horaires.svg");
						background-repeat: no-repeat;
						width: 22px;
						height: 27px;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: -30px;
					}

					&.active {
						&:before {
							content:"";
							position: absolute;
							background: url("../img/icon_horaires_orange.svg");
							background-repeat: no-repeat;
							width: 22px;
							height: 27px;
							left: 0;
							right: 0;
							margin: 0 auto;
							top: -30px;
						}
					}
				}

				.affluence {
					position: relative;

					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_affluence.svg");
						background-repeat: no-repeat;
						width: 22px;
						height: 27px;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: -30px;
					}

					&.active {
						&:before {
							content:"";
							position: absolute;
							background: url("../img/icon_affluence_orange.svg");
							background-repeat: no-repeat;
							width: 22px;
							height: 27px;
							left: 0;
							right: 0;
							margin: 0 auto;
							top: -30px;
						}
					}
				}

				.paiement {
					position: relative;

					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_paiement.svg");
						background-repeat: no-repeat;
						width: 27px;
						height: 27px;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: -30px;
					}

					&.active {
						&:before {
							content:"";
							position: absolute;
							background: url("../img/icon_paiement_orange.svg");
							background-repeat: no-repeat;
							width: 27px;
							height: 27px;
							left: 0;
							right: 0;
							margin: 0 auto;
							top: -30px;
						}
					}
				}

				.tarifs {
					position: relative;

					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_tarifs.svg");
						background-repeat: no-repeat;
						width: 28px;
						height: 28px;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: -30px;
						transition: .3s;
					}

					&.active {
						&:before {
							content:"";
							position: absolute;
							background: url("../img/Icon_tarifs_orange.svg");
							background-repeat: no-repeat;
							width: 28px;
							height: 28px;
							left: 0;
							right: 0;
							margin: 0 auto;
							top: -30px;
						}
					}
				}	
			}
			// END SOUS NAV INFORMATIONS

			.theinformations {
				position: relative;

				.informations-item {
					margin-top: 35px;
					opacity:0;
					z-index: -1;
					position: absolute;
					top:0;
					left: 0;
					display: none;
					&.show {
						position: relative;
						opacity: 1;
						z-index: 400;
						display: block;
						@include fadeInUp($duration: 0.3s,$count: 1,$delay: 0s,$function: ease, $fill: both);
					}
				}
			}

			// CONTACT
			#contact {
				.adress {
					position: relative;
					border-bottom: 1px solid rgba(255, 255, 255, .35);
					padding-bottom: 15px;
					
					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_location.svg");
						background-repeat: no-repeat;
						background-size: contain;
						width: 16px;
						height: 20px;
						left: 0;
					}

					p {
						font-family: $nuR;
						@include font-size(20);
						margin-left: 35px;
						margin-bottom: 0px;
					}
				}

				.phone {
					position: relative;
					margin-top: 15px;
					margin-bottom: 17px;

					&:before {
						content:"";
						position: absolute;
						background: url("../img/icon_phone.svg");
						background-repeat: no-repeat;
						background-size: contain;
						width: 16px;
						height: 20px;
						left: 0;
						top: 6px;
					}

					a {
						font-family: $nuEB;
						color: $white;
						@include font-size(22);
						margin-left: 35px;
						margin-bottom: 0px;
					}
				}

				.laverie-img {
					img {
						width: 100%;
						height: 200px;
						object-fit: cover;
						border-radius:20px;
					}
				}
			}

			// HORAIRES
			#horaires {
				.horaires-informations {
					@include font-size(20);

					.horaires-informations-day {
						font-family: $nuR;
						margin-bottom: 10px;

						span {
							font-family: $nuB;
						}
					}
				}
			}

			// AFFLUENCES
			#affluence {
				.affluence-select {
					@extend .d-flex, .justify-content-between;
					margin-bottom: 20px;

					.btn-affluence {
						text-transform: uppercase;
						color: $white;
						font-family: $nuR;
						padding: 3px;

						&.active {
							position: relative;
							&:before {
								content: "";
								position: absolute;
								width: 100%;
								height: 3px;
								bottom: 0;
								border-radius: 5px;
								background-color: $orange;
							}
						}
					}
				}

				.theaffluence {
					position: relative;
					padding-bottom: 5px;

					.paliers {
						position: absolute;
						width: 100%;
						height: 100%;
						border-bottom: 1px solid rgba(255, 255, 255, .7);
						z-index: 400;
						top: 0;
						.paliers-first {
							height: 33.3%;
							border-top: dashed 1px rgba(255, 255, 255, .4);
						}

						.paliers-second {
							height: 33.3%;
							border-top: dashed 1px rgba(255, 255, 255, .4);
						}

						.paliers-third {
							height: 33.3%;
							border-top: dashed 1px rgba(255, 255, 255, .4);
						}

						.hours {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							margin-top: 20px;

							p {
								font-family: $nuB;
								@include font-size(17);
								position: relative;

								&:before {
									content: "";
									position: absolute;
									width: 2px;
									height: 8px;
									background-color: $white;
									left: 0;
									right: 0;
									margin: 0 auto;
									top: -19px;
								}
							}
						}
					}

					.affluence-list {
						position: relative;
						z-index: 500;
						display: none;
						&.show {
							display: block;
						}
						canvas {
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}
.hebdo-graph {
	width: 100%;
	height: 135px;
}
