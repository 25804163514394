$w: #fff;
$blue: #087D9C;
$green: #077C9A;
$orange: #FF8C7D;
$blue-light: #25B4CC;

.orange {
	color: $orange;
}
.green {
	color: $green;
}

.back-blue-dark {
	background-color: $green;
	color: #fff;
}
.back-blue-light {
	background-color: $blue-light;
	color: #fff;
}

.back-orange, .backorange  {
	background-color: $orange;
	color: $w;
}