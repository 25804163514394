#home {
	height: 100vh;
	position: relative;
	.hometitle {
		padding: 60px 50px;
		z-index: 200;
		position: relative;
		background-color: #077C9A;
		box-shadow: 0 3px 45px #00546A9D;
		max-width: 610px;
		border-radius: 15px;
		@include fadeInLeft($duration: 1s,$count: 1,$delay: 1s,$function: ease, $fill: both);
		h1 {
			text-transform: uppercase;
			font-family: $nuEB;
			@include font-size(28);
			@include line-height(40);
			color: $w;
			cursor:pointer;
		}
		.subtitle {
			@include font-size(24);
			@include line-height(30);
			font-family: $nuR;
			color: $w;
			margin-top: 15px;
		}
		.btn {
			margin-top: 30px;
		}
	}
	.godown {
		position: absolute;
		z-index: 300;
		bottom: 0;
		left: 50%;
		background-color: #25B4CC;
		padding: 8px 16px 3px;
		border-top-right-radius: 45px;
		border-top-left-radius: 45px;
		transform: translate(-50%, 0);
		cursor: pointer;	
		text-decoration: none;
		@extend .transition;
		@include fadeIn($duration: 1s,$count: 1,$delay: 1s,$function: ease, $fill: both);	
		&:hover {
			background-color: $orange;
		}
		i {
			@include font-size(18);
			@include fadeInDown($duration: 2s,$count: infinite,$delay: 1s,$function: ease, $fill: both);	
			&:before {
				color: $w;
			}
		}
	}
}
#benefits {
	.part-right {
		position: relative;

		ul {
			// list-style-image: url("../img/list_style.png");
			list-style: none;
			padding-left: 0px;
			li {
				position: relative;
				padding-left: 20px;

				&:before {
					content:'';
					position: absolute;
					background-image: url("../img/list_style.png");
					width: 19px;
					height: 10px;
					background-size: contain;
					background-repeat: no-repeat;
					left: 0;
					top: 9px;
				}
			}
		}
		.proxisvg {
			@extend .d-none, .d-lg-block;
			position: absolute;
			bottom: 40px;
			right: 60px;
		}
	}

}
.backimg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	overflow: hidden;
	img {
		@extend .img-fluid;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}






#contacts {
	color: #fff;
	padding: 80px 0;
	text-align: center;
	position: relative;
	.container {
		z-index: 400;
		position: relative;
	}
	h2 {
		font-family: $nuEB;
		@include font-size(28);
		text-transform: uppercase;
		margin-bottom: 30px;
		@include fadeInLeft($duration: 1s,$count: 1,$delay: 0.4s,$function: ease, $fill: both);
	}
	.subttl {
		font-family: $nuR;
		@include font-size(25);
		@include line-height(28);
		@include fadeInUp($duration: 1s,$count: 1,$delay: 0.7s,$function: ease, $fill: both);
	}
	.map-container {
		@include fadeInUp($duration: 1s,$count: 1,$delay: 1s,$function: ease, $fill: both);
	}
	#map {
		height: 650px;
		max-width: 1088px;
		border-radius: 25px;
		margin-top:60px;
		@extend .mx-auto;
		@include media-breakpoint-down(md) {
			height: 400px;
		}
	}
}