// Media Left

.media-left {
	@extend .container-fluid;
	.part-left {
		@extend .col-md-5;
		@include fadeInLeft($duration: 1s,$count: 1,$delay: 0.3s,$function: ease, $fill: both);
		&.media {
			position: relative;
			overflow: hidden;
		}
	}
	.part-right {
		@extend .col-md-7;
		padding:10rem ;
		@include fadeInRight($duration: 1s,$count: 1,$delay: 0.7s,$function: ease, $fill: both);
		@include media-breakpoint-down(sm) {
			padding: 2rem;
		}

	}
	h2 {
		text-transform: uppercase;
		font-family: $nuEB;
		@include font-size(25);
		@include line-height(40);
		margin-bottom: 30px;
	}
	ul {
		padding-left: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		li {
			@include font-size(18);
			font-family: $nuSB;
			margin-bottom: 10px;
		}
	}
	.btn {
		margin-top: 30px;
	}
	.media {
		img {
			@extend .img-fluid;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

// 4 blocks with icons
.blocks-icons {
	margin-top: 130px;
	margin-bottom: 130px;
	h2 {
		text-align: center;
		text-transform: uppercase;
		@include font-size(28);
		font-family: $nuEB;
		margin-bottom: 20px;
		@include fadeInRight($duration: 1s,$count: 1,$delay: 0.4s,$function: ease, $fill: both);
	}
	.subttl {
		text-align: center;
		font-family: $nuR;
		@include font-size(25);
		@include line-height(40);
		margin-bottom: 60px;
		@include fadeInRight($duration: 1s,$count: 1,$delay: 0.7s,$function: ease, $fill: both);
	}
	.block-icon {
		@extend .col-sm-6,.col-xl-3;
		margin-bottom: 20px;
		@for $i from 1 through 6 {
			&:nth-of-type(#{$i}) {
				@include fadeInUp($duration: (#{$i*0.3s}), $count: 1,$delay:(#{$i*0.1s}), $function: ease, $fill: both);
			}
		} 
		.content {
			border-radius: 15px;
			box-shadow: 0 3px 10px #0000001A;
			padding: 40px;
			text-align: center;
			height: 100%;
			.icon {
				height: 120px;
				@extend .d-flex;
				img {
					height: 100%;
					width: auto;
					object-fit: cover;
					@extend .mx-auto;
				}
			}
			h3 {
				margin-top:25px;
				font-family: $nuM;
				@include font-size(18);
				@include line-height(22);
			}
		}
	}
	.button {
		margin-top: 60px;
		text-align: center;
		@include fadeInUp($duration: 1s,$count: 1,$delay: 0.7s,$function: ease, $fill: both);
	}
}


// Liste laveries
.laverie-list {
	.subtitle {
		text-align: center;
		font-family: $nuR;
		@include font-size(20);
		margin-bottom: 25px;
	}
	ul {
		list-style:none;
		padding-left:0;
		li {
			border-top:solid 1px #ffffff2b;
			padding:25px 30px;
			text-align: center;
			a {
				font-family: $nuEB;
				@include font-size(25);
				text-transform: uppercase;
				text-align: center;
				color: $w;
			}
			&:last-child {
				border-bottom:solid 1px #ffffff2b;
			}
		}
	} 
}

// Pages Machines
.block-lav {
	position: relative;
	.lav-title {
		position: relative;
		margin-bottom: 35px;
		display:flex;
		h2 {
			@extend .mx-auto;
			position: relative;
		}
		a {
			position: absolute;
			right: -40px;
			top: 36%;
			transform: translate(0, -50%);
			display: block;
			width: 22px;
			height: 22px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.action-row {
		.action-block {
			margin-bottom: 35px;
			position: relative;
			@extend .col-12, .col-sm-6,.col-md-4;
			img {
				position: absolute;
				top: 50%;
				left: 8%;
				transform: translate(0,-50%);
			}
			a {
				border-radius: 10px;
				width: 100%;
				background-color: $w;
				color: $green;
				padding: 30px;
				padding-left: 20%;
				box-shadow: 0 5px 15px #24282926;
				font-family: $nuEB;
				text-transform: uppercase;
				@include font-size(20);
				&:focus, &:active, &:hover {
					background-color: $orange;
				}
				&.qrcode {

				}
				&.machines {

				}
			}
		}
	} 
}

// Liste des machines
.machines-list {
	.machines-select {
		@extend .d-flex, .justify-content-center;
		margin-bottom: 20px;	
	}

	.thelist {
		position: relative;
	}
	.lavl-list {
		opacity:0;
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		&.show {
			position: relative;
			opacity: 1;
			z-index: 400;
			@include fadeInUp($duration: 0.3s,$count: 1,$delay: 0s,$function: ease, $fill: both);
		}
		.subttl {
			margin-bottom: 50px;
		}
	}
	.lavl {
		@extend .col-6, .col-md-4, .col-xxl-2;
		color: $green;
		padding-left: 5px;
		padding-right: 5px;
		margin-bottom: 70px;

		text-decoration: none;
		cursor:default;
		.content {
			background-color: $w;
			border-radius: 10px;
			padding: 30px 11px 10px 11px;
			text-align: center;
			position: relative;
			height: 100%;
			box-shadow: 0 5px 15px #24282926;
			

			.mach-num {
				font-family: $nuBL;
				@include font-size(24);
				width: 49px;
				height: 49px;
				text-align: center;
				position: absolute;
				top: -25px;
				background-color: #fff;
				border-radius: 50%;
				left: 50%;
				transform: translate(-50%,0);
				@extend .d-flex, .transition;
				div {
					@extend .m-auto;
				}
			}
			.title {
				font-family: $nuEB;
				@include font-size(18);
				margin-bottom: 7px;
			}
			.status {
				color: #242829CC;
				font-family: $nuR;
				@include font-size(18);
				margin-bottom: 7px;
			}

			.timer {
				position:relative;
				width: 100%;
				.graph {
					width: 100%;
					height: 120px;
				}
				.avail {
					position: absolute;
					transform: translate(-50%, -50%);
					left: 50%;
					top: 50%;
					.avail-status {
						font-family: $nuBL;
						@include font-size(20);
						text-transform: uppercase;
					}
				}
			}

		}
		&.ended {
			cursor: pointer;
			@extend .transition;
			&:hover, &:focus, &:active {
				transform:translate(0, -10px);
			}
			.lasting, .unit {
				display: none;
			}
			.mach-num {
				box-shadow: 0 3px 6px #24282926;
			}

		}
	}
}


// Page pricing
.pricing {
	@extend .flex-nowrap;
	overflow: hidden;
	overflow-x: auto;
	padding: 50px 10px;
	.prog {
		height: 114px;
		width: 114px;
		flex-shrink: 0;
		background-color: #fff;
		border-radius: 50%;
		margin-right: 14px;
		text-decoration: none !important;
		@extend .d-flex, .transition;
		box-shadow: 0 5px 15px transparent;
		&:hover, &.active {
			transform: scale(1.4);
			box-shadow: 0 5px 15px #24282926;
		}
		&.active {
			background-color: $blue-light;
			.content {
				color:$w;
				.price {
					color: $w;
				}
			}
		} 
		.content {
			@extend .m-auto;
			color: $green;
			text-align: center;
			.time {
				.big {
					font-family: $nuEB;
					@include font-size(36);
					@include line-height(36);
				}
				.small {
					font-family: $nuM;
					@include font-size(12);
				}
			}
			.price {
				margin-top: 7px;
				font-family: $nuEB;
				@include font-size(15);
				color: #24282980;
			}
		}
	}

}
.back-blue-light {
	.pricing {
		.prog {
			&.active {
				background-color: $orange;
			}
		}
	}
}


// Order summary
.order-summery {
	background-color: white;
	color: $green;
	padding: 50px 25px;
	max-width: 400px;
	margin: auto;
	border-radius: 10px;
	box-shadow: 0 5px 15px #24282926;
	@include fadeInUp($duration: 0.3s,$count: 1,$delay: 0s,$function: ease, $fill: both);
	.title {
		font-family: $nuB;
		@include font-size(24);
		text-align: center;
		margin-bottom: 20px;
	}
	.date {
		color: #24282980;
		font-family: $nuR;
		@include font-size(18);
		text-align: center;
		padding-bottom: 20px;
		margin-top: 20px;
		&:after {
			content: '';
			display: block;
			width: 166px;
			background-color: #2428293b;
			height: 1px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 30px;
			margin-top: 10px;
		}

	}
	.material {
		font-family: $nuR;
		@include font-size(18);
		color: #242829CC;
		margin-bottom: 15px;
		&-title {
			font-family: $nuB;
			@include font-size(18);
		}
	}
	.lavl {
		.timer {
			position: relative;
			.graphVal, .graphValSech  {
				width: 100%;
			}
			.avail {
				position: absolute;
				top:50%;
				left: 50%;
				transform: translate(-50%,-50%);
				text-align: center;
				.avail-status {
					font-family: $nuBL;
					@include font-size(34);
					line-height: 34px;
					text-transform: uppercase;
				}
			}
		}
	}
}

.summary {
	@extend .d-flex, .justify-content-center;
	margin-top: 30px;
	.time {
		font-family: $nuEB;
		@include font-size(22);
		border-right: 1px solid #ffffff91;
		@extend .my-auto;
		padding-right: 15px; 
		span {
			font-family: $nuM;
			@include font-size(20);
		}
	}
	.price {
		font-family: $nuEB;
		@include font-size(40);
		@extend .my-auto;
		padding-left: 15px; 

		sup {
			font-family: $nuM;
			@include font-size(18);
			margin-left: 7px;
		}
	}
}