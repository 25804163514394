.logo-big-connex {
	max-width: 265px;
	padding-top: 10rem;
	margin-bottom: 30px;
	@extend .mx-auto;
	img {
		@extend .img-fluid;
		width: 100%;
	}
}
.login-form {
	form {
		background-color: $orange;
		max-width: 600px;
		padding:40px 80px;
		@extend .mx-auto;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		color: #fff;
		input {
			background-color: transparent;
			color: #fff;
			font-family: $nuM;
			@include font-size(22);
			border:none;
			border-bottom:2px #fff solid;
			border-radius: 0;
		}
		input::placeholder {
			color: #fff;
		}
		.form-control:focus {
			color: #fff;
			background-color: transparent;
			border-color: #FC7A70;;
			outline: 0;
			box-shadow: none;
		}
		.custom-control {
			padding-left: 3.5rem;
			padding-top: 1rem;
			margin-top: 30px;
			&.custom-checkbox {
				.custom-control-label {
					&:before {
						width: 2rem;
						height: 2rem;
						left: -2.5rem;
						top: -0.2rem;
						border: none;
					}
					&:after {
						top: -0.2rem;
						left: -2.5rem;
						width: 2rem;
						height: 2rem;
					}
				}
			} 
		}
		.custom-control-input:checked ~ .custom-control-label::before {
			color: #fff;
			border-color: #FC7A70;
			background-color: #FC7A70;
			box-shadow: 0 0 0 0.2rem #FC7A70;
		}
		.custom-control-label::before {
			box-shadow: 0 0 0 0.2rem #FC7A70 !important;
		}
		.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
			color: #fff;
			background-color: #5cd8f8;
			border-color: #5cd8f8;
		}
		.custom-control-label {
			font-family: $nuB;
			@include font-size(20);
		}
		.reset-pass {
			text-align: center;
			margin-top: 30px;
			a {
				font-family: $nuR;
				@include font-size(18);
				text-decoration: underline;
				color:$w;
			}

		}
		button {
			@extend .btn, .btn-proxi, .btn-proxi-white;
			margin-top:45px;
			display: block;
			text-transform: uppercase;
			@extend .mx-auto;
		}
	}
}