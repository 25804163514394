@include font-face('NunitoBlack', '../fonts/Nunito-Black');
@include font-face('NunitoBold', '../fonts/Nunito-Bold');
@include font-face('NunitoExtraBold', '../fonts/Nunito-ExtraBold');
@include font-face('NunitoExtraLight', '../fonts/Nunito-ExtraLight');
@include font-face('NunitoLight', '../fonts/Nunito-Light');
@include font-face('NunitoMedium', '../fonts/Nunito-Regular');
@include font-face('NunitoRegular', '../fonts/Nunito-Light');
@include font-face('NunitoSemiBold', '../fonts/Nunito-SemiBold');

$nuBL: "NunitoBlack";
$nuB: "NunitoBold";
$nuEB: "NunitoExtraBold";
$nuEL: "NunitoExtraLight";
$nuL: "NunitoLight";
$nuM: "NunitoMedium";
$nuR: "NunitoRegular";
$nuSB: "NunitoSemiBold";

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?jhvg83');
  src:  url('../fonts/icons/icomoon.eot?jhvg83#iefix') format('embedded-opentype'),
  url('../fonts/icons/icomoon.ttf?jhvg83') format('truetype'),
  url('../fonts/icons/icomoon.woff?jhvg83') format('woff'),
  url('../fonts/icons/icomoon.svg?jhvg83#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_account:before {
  content: "\e900";
  color: #fff;
}
.icon-down-arrow:before {
  content: "\e901";
}


html {
	font-size: 0.9rem;

}

body {
	font-family: $nuR;
	color: $blue;
}

.transition {
	transition: all 0.3s ease-in-out;
}

@include media-breakpoint-up(sm) {
	html {
		font-size: 0.7rem;
	}
}


@include media-breakpoint-up(xl) {
	html {
		font-size: 0.8rem;
	}
}

@include media-breakpoint-up(xxl) {
	html {
		font-size: 1rem;
	}
}