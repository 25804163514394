#homepage {
	#main-nav {
		@include slideInDown($duration: 1s,$count: 1,$delay: 1s,$function: ease, $fill: both);
	}

}

#main-nav {
	background-color: $w !important;
	box-shadow: 0 0 5px #42527321;
	// @include slideInDown($duration: 1s,$count: 1,$delay: 1s,$function: ease, $fill: both);
	padding-top:0;
	padding-bottom:0;

	.navbar-brand {

		width: 15%;
		img {
			@extend .img-fluid;
			margin-top: -0.5rem;
		}
	}

	.menu-lang {
		@extend .d-none, .d-lg-flex, .ml-auto;
		cursor:pointer;
		color:#2428294D;
		font-family: $nuM;
		position: relative;
		@include font-size(18);
		&:hover {
			color:#FF8C7D;
			.picto {
				opacity: 0;
			}
			.picto-h {
				opacity: 1;
			} 
			.arrow-down {
				border-top: 5px solid #FF8C7D;
			}
		}
		div {
			@extend .my-auto;
			@extend .transition;
		}
		.picto {
			width: 25px;
			margin-right: 5px;
			opacity: 1;
			position: absolute;
			left: -30px;
			top: -2px;
			@extend .transition;
		}
		.picto-h {
			opacity: 0;
			width: 25px;
			margin-right: 5px;
			position: absolute;
			left: -30px;
			top: -2px;
			@extend .transition;
		}
		.arrow-down {
			width: 0; 
			height: 0; 
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #2428294D;
			margin-left: 5px;
		}

	}
	.navbar-nav {
		@include media-breakpoint-down(md) {
			height: 100vh;
		}
	}
	.money {
		@extend .d-flex, .d-lg-none;
		text-decoration: none;
		position: relative;
		@extend .ml-auto;
		span {
			background-color: #077C9A;
			color: #fff;
			padding: 5px 40px 5px 10px;
			border-top-left-radius: 50px;
			border-bottom-left-radius: 50px;
			border-radius: 50px;
			display: block;
			min-width: 104px;
			@extend .my-auto;
			font-family: $nuB;
			@include font-size(16);
			text-transform: uppercase;
		}
		img {
			position: absolute;
			right: -40px;
			top: 50%;
			transform: translate(-50%,-50%);
			@extend .my-auto;
		}
	}
	.nav-item {
		@extend .d-flex;
		@include media-breakpoint-down(md) {
			border-bottom: 1px solid rgba($green, 0.3);
			text-align: center;
		}
		&.active {
			.nav-link {
				color: $orange;
				&:after {
					background-color: $orange;
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}
		}
		.nav-link {
			text-transform: uppercase;
			color: $blue;
			font-family: $nuBL;
			letter-spacing: 0.4px;
			@extend .my-auto;
			@include font-size(16);
			@extend .transition;
			@include media-breakpoint-down(md) {
				margin: auto;
				padding: 17px;
			}
			&:after {
				content:'';
				background-color: transparent;
				width: 100%;
				height: 3px;
				display: block;
			}
			&:hover, &.active {
				color: $orange;
			}

			@include media-breakpoint-up(md) {
				padding:0.5rem 0.5rem;
			}
			@include media-breakpoint-up(lg) {
				padding:0.5rem 1rem;
			}
			@include media-breakpoint-up(xl) {
				padding:1.5rem 1.5rem;
			}

		}
		&.account {
			background-color: $orange;
			color: #fff;
			margin-right: -20px;
			padding-top: 10px;
			padding-bottom: 10px;
			@extend .transition;
			@include media-breakpoint-down(md) {
				margin-right: 0;
			}
			.nav-link {
				height: 100%;
				color: $w;
				text-align: center;
				font-family: $nuEB;
				@extend .transition;
				position: relative;

				&:before {
					content:"";
					position: absolute;
					background-image: url("../img/icon_account.png");
					background-repeat: no-repeat;
					background-size: contain;
					width: 30px;
					height: 30px;
					left: 75px;
					top: 4px;
				}

				.subtitle {
					font-family: $nuR;
					@include font-size(11);
					text-transform: uppercase;
					letter-spacing: 2.2px;
					color: $w;
					@extend .transition;
				}
			}

			&:hover {
				background-color: #FD7B6A;
				// .nav-link {
				// 	color: $orange;
				// 	i {
				// 		&:before {
				// 			color: $orange;
				// 		}
				// 	}
				// 	.subtitle { 
				// 		color: $orange;
				// 	}
				// }
			}
			i {
				@extend .transition;
				&:before {
					@extend .transition;
				}
				text-align: center;
				@include font-size(23);
			}
		}
	}
	button {
		outline: none !important;
	}
}

body.scrolled {
	#main-nav {
		.nav-item { 
			.nav-link {
				@include media-breakpoint-up(xl) {
					padding:0.5rem 1.5rem;
				}
			}
			&.account {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}
