$theme-colors: (
	"primary": #077C9A,
	"dark": #000000
	);

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px
	) !default;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1589px
	) !default;


$hamburger-padding-x           : 18px !default;
$hamburger-padding-y           : 18px !default;
$hamburger-layer-width         : 20px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #077C9A !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : #077C9A !default;
$hamburger-active-hover-opacity: 1 !default;